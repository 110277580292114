.components-table-demo-control-bar .ant-form-item {
	margin-right: 16px !important;
	margin-bottom: 8px !important;
}
.field_title {
	.show_group_button {
		height: 22px;
		span {
			display: none;
		}
	}
}

.ant-table-thead > tr > * {
	background-color: white !important;
}

.ant-table-row:hover .show_group_button span {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 5px;
}

.ant-table-tbody > :nth-child(even) {
	background-color: #fafafa !important;
}
.ant-table-cell {
	border-bottom: none !important;
	cursor: pointer;
}
.ant-table-tbody:hover > tr {
	td:first-child {
		border-radius: 10px 0 0 10px !important;
	}
	td:last-child {
		border-radius: 0 10px 10px 0 !important;
	}
}
.ant-table-cell-row-hover {
	color: #25acff;
	background-color: #e7f1ff !important;
	.gr_btn_table {
		display: flex;
	}
}

// table-manager-custom
.table-manager-custom {
	thead tr th {
		color: #7e7878 !important;
	}

	.ant-table-header {
		border-radius: 24px !important;
		// border: 1px solid #1a1a1a !important;
		box-shadow: 0px 20px 67px -20px rgba(0, 0, 0, 0.8) !important;
		margin-bottom: 3px;
	}
	.ant-table-thead > tr > * {
		background-color: white !important;
	}

	.ant-table-row:hover .show_group_button span {
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 5px;
	}

	.ant-table-tbody > * {
		background-color: #fff !important;
	}
	.ant-table-tbody .ant-table-cell {
		border-bottom: 1px dashed #1a1a1a !important;
		cursor: pointer;
	}
	.ant-table-cell-row-hover {
		color: #25acff;
		background-color: #e7f1ff !important;
	}
}

.contact_cell {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 4px;
	color: inherit;
	cursor: pointer;
	display: flex;
	gap: 8px;
	padding: 4px 8px;
	text-decoration: none;
	width: fit-content;
}
.contact_cell svg {
	width: 20px;
	transition: transform 0.3s ease-in-out;
}

@keyframes left-vibrate-ani {
	0% {
		transform: scale(1) rotate(0deg) translateX(0px);
	}
	25% {
		transform: scale(1.1) rotate(-15deg) translateX(-4px);
	}
	50% {
		transform: scale(1.1) rotate(0deg) translateX(-4px);
	}
	75% {
		transform: scale(1.1) rotate(-15deg) translateX(-4px);
	}
	100% {
		transform: scale(1) rotate(0deg) translateX(0px);
	}
}

.contact_cell:hover svg {
	animation: left-vibrate-ani 0.8s ease-in-out;
}
.ant-table-row:hover {
	.contact_cell {
		background-color: #fff;
	}
	.btn_setting {
		background-color: #fff;
	}
}

.contact_cell:hover {
	border-color: #25acff;
}
.empty-text {
	color: #ccc;
	font-size: 14px;
	font-weight: 600;
}
