// Screen quayletan
.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width) */
	height: 0;
	overflow: hidden;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#fullscreen {
	padding: 16px;
	background: rgb(37, 79, 116);
	background: linear-gradient(
		34deg,
		rgba(37, 79, 116, 1) 0%,
		rgba(255, 255, 255, 1) 100%
	);
}

// screen services
.mdf_container {
	height: calc(100vh - 160px);
	display: flex;
	gap: 16px;
	padding: 10px 0 !important;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		height: calc(100vh - 200px);
	}
}

.mdf_content {
	height: 100%;
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
#fullscreen2 {
	padding: 8px 16px;
	background: white;
	.style-form-item .ant-form-item {
		margin-bottom: 0 !important;
	}
}
