.ant-input-suffix {
	display: flex !important;
	align-items: stretch !important;
}
.ant-input-group-addon button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.box_content {
	.ant-tabs-ink-bar {
		background: #326b97 !important;
	}
	.ant-pagination-item-link,
	.ant-pagination-item-container {
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
	.ant-pagination-item-container,
	.ant-pagination-item-link {
		height: 100%;
	}
}
.box_menu_post {
	width: calc(100% / 3);
}
@media screen and (max-width: 1410px) {
	.box_menu_post {
		width: 50%;
	}
}
@media screen and (max-width: 990px) {
	.box_menu_post {
		width: 90%;
	}
}
