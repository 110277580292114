.ant-modal {
	@media screen and (max-width: 768px) {
		max-width: 100vw !important;
		max-height: 100vh !important;
		width: 100vw !important;
		height: 100vh !important;
		top: 0 !important;
	}
}

.mdf_container {
	height: calc(100vh - 160px);
	display: flex;
	gap: 16px;
	padding: 10px 0 !important;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		height: calc(100vh - 200px);
	}
}

.mdf_content {
	height: 100%;
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.ant-form-item {
	margin-bottom: 10px !important;
}

// drawer Modal
.drawerModal-screen-services {
	.mdf_container {
		height: calc(100vh - 120px);
		display: flex;
		gap: 16px;
		padding: 10px 0 !important;
		@media screen and (max-width: 1024px) {
			flex-direction: column;
			height: calc(100vh - 200px);
		}
	}
}

@media screen and (min-width: 2536px) {
	.ant-collapse-expand-icon {
		height: 37px !important;
	}
	.ant-collapse-header-text {
		font-size: 24px;
	}
}
