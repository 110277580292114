.ant-drawer-body {
	padding: 0px !important;
	.left_menu_mobile {
		ul {
			border-inline-end: none !important;
		}
		::-webkit-scrollbar {
			width: 0px !important; /* Chiều rộng */
		}
	}
}
