.box_editor_draft {
	::-webkit-scrollbar {
		width: 0px;
	}
	.rdw-image-imagewrapper {
		height: fit-content;
	}
	.rdw-fontfamily-optionwrapper {
		width: 160px !important;
	}
	.rdw-editor-main {
		height: calc(100vh - 145px) !important;
	}
	.rdw-image-modal,
	.rdw-embedded-modal,
	.rdw-emoji-modal,
	.rdw-link-modal {
		right: 0 !important;
		left: auto !important;
	}
	@media screen and (max-width: 1425px) {
		.rdw-editor-main {
			height: calc(100vh - 165px) !important;
		}
	}
	.rdw-embedded-modal,
	.rdw-link-modal {
		width: 260px !important;
		height: 230px !important;
	}
	.rdw-editor-main h1 {
		display: block;
		font-size: 2em;
		margin-block-start: 0.67em;
		margin-block-end: 0.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	.rdw-editor-main h2 {
		display: block;
		font-size: 1.5em;
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	.rdw-editor-main h3 {
		display: block;
		font-size: 1.17em;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	.rdw-editor-main h4 {
		display: block;
		margin-block-start: 1.33em;
		margin-block-end: 1.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	.rdw-editor-main h5 {
		display: block;
		font-size: 0.83em;
		margin-block-start: 1.67em;
		margin-block-end: 1.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	.rdw-editor-main h6 {
		display: block;
		font-size: 0.67em;
		margin-block-start: 2.33em;
		margin-block-end: 2.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	.rdw-editor-main a {
		font-size: 14px;
		color: #008cff;
		text-decoration: underline;
	}
}
