.box_signin {
	background-image: url("../../assets/images/web_designs.jpg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-position-x: right;
	.button_signin {
		.ant-form-item-row {
			width: 100% !important;
		}
	}
	@media screen and (max-width: 1024px) {
		background-image: none;
	}
}
